html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}

.App {
  text-align: center;
}

.navbar {
  background-color: #282c34;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

/* Desktop navigation */
.desktop-nav {
  display: flex;
  align-items: center;
}

.desktop-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.desktop-nav li {
  margin: 0 1rem;
}

.desktop-nav a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: opacity 0.3s ease;
}

.desktop-nav a:hover {
  opacity: 0.8;
}

/* Mobile menu button - hidden by default */
.menu-toggle {
  display: none;
}

/* Mobile drawer - hidden by default */
.side-drawer {
  display: none;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* Hide desktop navigation */
  .desktop-nav {
    display: none;
  }

  /* Show mobile menu button */
  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .menu-toggle span {
    width: 100%;
    height: 2px;
    background-color: white;
    transition: all 0.3s ease;
  }

  /* Show mobile drawer */
  .side-drawer {
    display: block;
    position: fixed;
    top: 0;
    left: -280px;
    width: 280px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
    z-index: 1001;
  }

  .side-drawer.open {
    left: 0;
  }

  /* Rest of your existing mobile styles... */
}

:root {
  --home-bg-size: 100%; /* This can be 'cover', 'contain', or a percentage like '150%' */
}
.home-section {
  width: 100%;
  height: 100vh;
  background-image: url('./images/LuchianBG.png');
  background-size: var(--home-bg-size);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}

.home-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}


.left-img {
  height: auto;
  max-width: 500px; /* Adjust this value as needed */
  display: block;
}

.right-img {
  height: auto;
  max-width: 500px; /* Adjust this value as needed */
  display: block;
}

.home-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
}

.home-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: white;
}

.home-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: white;
}

.home-images {
  display: flex;
  justify-content: center;
  gap: 14rem; /* This creates space between the images */
}

.home-logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid white;
  object-fit: cover; /* This ensures the image fills the circular shape */
}

main {
  padding: 0;
  max-width: none;
}

section:not(#home) {
  max-width: 1000px;
  margin: 4rem auto;
  padding: 2rem;
  padding-top: 60px; /* Adjust this value based on your navbar height */
  margin-top: -60px; /* Negative margin to offset the padding */
}

h2 {
  color: #333;
  margin-bottom: 1rem;
}

p {
  color: #666;
  margin-bottom: 1rem;
}

.join-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.join-button:hover {
  background-color: #0056b3;
}
.past-events {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.past-events h3 {
  margin-bottom: 0.5rem;
  color: #333;
}

.past-events p {
  margin-bottom: 0.5rem;
  font-style: italic;
}

.past-event-links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.past-event-link {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.past-event-link:hover {
  background-color: #2681F2;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(45, 140, 255, 0.3);
}
.conference-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-bottom: 1rem;
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #666;
}

.conference-description {
  text-align: left;
}

.conference-description h3 {
  margin-bottom: 1rem;
}

.conference-description p, .conference-description ul {
  margin-bottom: 1rem;
}

.biblio-container {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.biblio-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2rem;
}

.biblio-image-container {
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 3/2;
}

.biblio-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.biblio-image-container:hover img {
  transform: scale(1.15);
}
/* .biblio-images img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */


.gallery-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-top: 2rem;
}
.gallery-images {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-top: 2rem;
}

.gallery-image-container {
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 3/2;
}

.gallery-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-image-container:hover img {
  transform: scale(1.15);
}



.flyer-image-container {
  overflow: hidden;
  border-radius: 8px;
  /* padding-left: 8%; */
  width: 100%;
  height: 120%;
  /* aspect-ratio: 3/2; */
}

.flyer-image-container img {
  width: 100%;
  height: 120%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.flyer-image-container:hover img {
  transform: scale(1.15);
}

.flyer-images {
  display:flex;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-top: 2rem;
}



.galleryfl-image-container {
  overflow: hidden;
  border-radius: 8px;
  /* padding-left: 8%; */
  width: 100%;
  height: 100%;
  /* aspect-ratio: 3/2; */
}

.galleryfl-image-container img {
  width: 40%;
  height: 40%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.galleryfl-image-container:hover img {
  transform: scale(1.15);
}

.galleryfl-images {
  display:block;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-top: 2rem;
}


.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modal-body img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.modal-header h3 {
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
}

.film-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-bottom: 1rem;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.film-description {
  text-align: left;
}

.film-description h3 {
  margin-bottom: 1rem;
}

.film-description p {
  margin-bottom: 1rem;
}

.login-container, .profile-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container input, .login-container button {
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.profile-info p {
  text-align: left;
}

.user-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 8px;
}

.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 180px;
  z-index: 1000;
  margin-top: 8px;
  padding: 8px 0;
}

.user-dropdown button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s ease;
}

.user-dropdown button:hover {
  background-color: #f5f5f5;
}

/* Add a small arrow at the top of the dropdown */
.user-dropdown::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 20px;
  width: 12px;
  height: 12px;
  background-color: white;
  transform: rotate(45deg);
  box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.1);
}

/* Ensure the arrow doesn't show the shadow through the menu */
.user-dropdown::after {
  content: '';
  position: absolute;
  top: 0;
  right: 15px;
  width: 22px;
  height: 12px;
  background-color: white;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.dropdown-toggle svg {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 150px;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-header {
  font-weight: bold;
  color: #333;
}

.dropdown-menu a, .dropdown-menu button {
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
}

.dropdown-menu a:hover, .dropdown-menu button:hover {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .navbar-content {
    flex-direction: column;
  }

  .navbar ul {
    margin-top: 1rem;
    justify-content: center;
  }

  .navbar li {
    margin: 0.5rem;
  }
  .gallery-images {
    grid-template-columns: repeat(2, 1fr);
  }
  .biblio-images {
      grid-template-columns: repeat(2, 1fr);
  }
  .home-logo {
    width: 100px;
    height: 100px;
  }
}

.login-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}

.auth-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.auth-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-container input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.auth-container button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-container button:hover {
  background-color: #0056b3;
}

.forgot-password, .toggle-form {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;
}

.forgot-password:hover, .toggle-form:hover {
  color: #0056b3;
}

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.profile-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.profile-info p {
  margin: 0.5rem 0;
}

.profile-info strong {
  font-weight: bold;
  margin-right: 0.5rem;
}

/* Add this new style for the user type */
.profile-info p:nth-child(3) strong {
  color: #007bff;
}

.admin-form {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.admin-form input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.admin-form button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-form button:hover {
  background-color: #0056b3;
}

/* Add these new styles for the contact section */
.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.contact-info, .contact-map {
  flex: 1;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.contact-item:hover {
  transform: translateY(-5px);
}

.contact-icon {
  margin-right: 1rem;
  font-size: 2rem;
  color: #8e44ad; /* Purple color */
  transition: color 0.3s ease, transform 0.3s ease;
}

.contact-item:hover .contact-icon {
  color: #e84393; /* Pink color */
  transform: scale(1.1);
}

.contact-item a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
}

.contact-item a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #8e44ad; /* Purple color */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.contact-item a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.contact-item.email a {
  background: linear-gradient(45deg, #8e44ad, #e84393); /* Purple to pink gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.contact-item.facebook a {
  background: linear-gradient(45deg, #8e44ad, #9b59b6); /* Dark purple to light purple gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.contact-map iframe {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
}

/* Add these new styles */

.home-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.home-content h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.home-content h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: white;
}

.project-funding {
  font-style: italic;
  margin-bottom: 2rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content, .conference-content, .biblio-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.about-content p, .conference-content p, .biblio-content p {
  margin-bottom: 1rem;
}

.flyer-images {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.join-button, .login-button {
  display: inline-block;
  margin-top: 1rem;
}

/* Update existing styles */

section:not(#home) {
  padding: 4rem 2rem;
  margin-top: 0;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}

/* ... rest of your existing styles ... */

.conference-content, .biblio-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.conference-content p, .biblio-content p {
  margin-bottom: 1rem;
}

.button-container {
  text-align: center;
  margin-top: 2rem;
}

.join-button, .login-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #8e44ad;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.join-button:hover, .login-button:hover {
  background-color: #e84393;
}

/* ... rest of your styles ... */

/* Updated button styles */
.join-button, .login-button {
  display: inline-block;
  padding: 10px 25px;
  background: linear-gradient(45deg, #8e44ad, #e84393);
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.join-button:hover, .login-button:hover {
  background: linear-gradient(45deg, #e84393, #8e44ad);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

/* Special styling for navbar login button */
.navbar .login-button {
  font-size: 1.1rem;
  padding: 8px 20px;
  background: transparent;
  border: 2px solid white;
  border-radius: 25px;
  color: white;
  margin-left: 15px;
  transition: all 0.3s ease;
}

.navbar .login-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: none;
}

/* Update form button styles */
.auth-container button[type="submit"] {
  background: linear-gradient(45deg, #8e44ad, #e84393);
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.auth-container button[type="submit"]:hover {
  background: linear-gradient(45deg, #e84393, #8e44ad);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

/* Update admin form button styles */
.admin-form button {
  background: linear-gradient(45deg, #8e44ad, #e84393);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.admin-form button:hover {
  background: linear-gradient(45deg, #e84393, #8e44ad);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

/* Update navbar styles */
.navbar {
  background-color: #282c34;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  color: white;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navbar li {
  margin-left: 1.5rem;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 8px 0;
}

/* Hamburger menu styles */
.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.menu-toggle span {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s linear;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: flex;
    margin-left: 1rem;
  }

  .navbar-content {
    padding: 0 1rem;
  }

  .navbar ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #282c34;
    padding: 1rem;
    text-align: center;
  }

  .navbar ul.active {
    display: flex;
  }

  .navbar li {
    margin: 1rem 0;
  }

  .navbar .login-button {
    margin: 1rem 0;
    width: 200px; /* Fixed width for better mobile appearance */
  }

  /* Keep login button visible */
  .navbar li.user-menu {
    display: flex !important; /* Override the display:none from .navbar ul */
    margin: 0 0 0 1rem;
  }

  /* Adjust other list items */
  .navbar li:not(.user-menu) {
    display: none;
  }

  .navbar ul.active li:not(.user-menu) {
    display: block;
  }
}

/* Additional mobile adjustments */
@media screen and (max-width: 430px) {
  .home-content {
    padding: 0 1rem;
  }

  .home-content h1 {
    font-size: 2rem;
  }

  .home-content h2 {
    font-size: 1.4rem;
  }

  .home-images {
    gap: 2rem;
  }

  .home-logo {
    width: 100px;
    height: 100px;
  }

  section:not(#home) {
    padding: 2rem 1rem;
  }

  .flyer-images {
    flex-direction: column;
    align-items: center;
  }

  .flyer-image-container {
    width: 100%;
    max-width: 300px;
  }
}

/* Navbar and drawer styles */
.navbar {
  background-color: #282c34;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Menu toggle button */
.menu-toggle {
  display: none; /* Hidden by default for desktop */
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}


.menu-toggle span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .menu-toggle {
    display: flex;
    margin-right: 1rem;
  }

  .navbar-content {
    justify-content: flex-start; /* Align items to the left */
  }

  .logo {
    margin-left: 1rem;
  }

  /* Side drawer styles */
  .side-drawer {
    position: fixed;
    top: 0;
    left: -280px;
    width: 280px;
    height: 100vh;
    background-color: white;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
    z-index: 1000;
    display: block;
  }

  .side-drawer.open {
    left: 0;
  }

  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  /* Drawer user section */
  .drawer-user-section {
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
  }

  .drawer-user-profile {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  .drawer-user-info {
    flex-grow: 1;
  }

  .drawer-user-name {
    margin: 0;
    font-weight: 500;
    color: #333;
  }

  /* Drawer navigation links */
  .drawer-nav-links {
    padding: 8px 0;
  }

  .drawer-nav-link {
    display: block;
    padding: 12px 20px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }

  .drawer-nav-link:hover {
    background-color: #f5f5f5;
  }

  /* Drawer dropdown menu */
  .drawer-dropdown {
    background-color: white;
    padding: 8px 0;
    margin-top: 8px;
  }

  .drawer-dropdown button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    border: none;
    background: none;
    color: #333;
    cursor: pointer;
    text-align: left;
  }

  .drawer-dropdown button:hover {
    background-color: #f5f5f5;
  }
}

/* Update existing auth-container styles */
.auth-container {
  max-width: 450px;
  margin: 2rem auto;
  padding: 2rem;
}

/* Add new styles */
.date-of-birth {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 1rem;
}

.react-select-container {
  margin-bottom: 1rem;
}

.react-select__control {
  border-radius: 4px !important;
  border: 1px solid #ddd !important;
}

.react-select__control:hover {
  border-color: #007bff !important;
}

.terms-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 1rem;
  text-align: left;
}

.terms-container input[type="checkbox"] {
  margin-top: 4px;
}

.terms-container label {
  font-size: 0.9rem;
  color: #666;
}

.terms-container a {
  color: #007bff;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}

.toggle-form-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 1rem;
}

.toggle-form-button:hover {
  color: #0056b3;
}

/* Mobile responsiveness */
@media screen and (max-width: 480px) {
  .auth-container {
    padding: 1rem;
    margin: 1rem;
  }

  .past-event-links {
    flex-direction: column;
    gap: 0.5rem;
  }

  .date-of-birth {
    grid-template-columns: 1fr;
  }

  .terms-container {
    font-size: 0.8rem;
  }
}

/* Add this new style for the dropdowns */
.inline-select {
  flex: 1; /* Allow the dropdowns to grow and fill the space */
  margin-right: 1rem; /* Add some space between them */
}

/* Adjust the last dropdown to remove the margin */
.inline-select:last-child {
  margin-right: 0; /* Remove margin for the last item */
}

/* Ensure the input fields for first name and last name are the same size */
.input-field {
  width: 100%; /* Make input fields take full width */
  margin-bottom: 1rem; /* Add some space below each input */
}

.educational-materials {
  margin-top: 2rem;
  text-align: center;
}

.material-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Space between buttons */
  margin-top: 1rem;
}

.material-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333; /* Text color */
  transition: transform 0.2s;
}

.material-button img {
  width: 100px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.material-button:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.material-button span {
  margin-top: 0.5rem; /* Space between image and text */
  font-weight: bold; /* Make text bold */
}


