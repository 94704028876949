.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  width: 60%;
  max-width: 600px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #8e44ad;
  transition: color 0.3s ease;
  z-index: 10;
}

.close-button:hover {
  color: #e84393;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(142, 68, 173, 0.7);
  border: none;
  font-size: 24px;
  padding: 10px 15px;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 10;
}

.nav-button:hover {
  background-color: rgba(232, 67, 147, 0.7);
  transform: translateY(-50%) scale(1.1);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.modal-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 300px;
}

.modal-image-container img {
  max-width: 100%;
  max-height: 50vh;
  object-fit: contain;
}

.modal-text-container {
  padding: 20px;
  max-height: 30vh;
  overflow-y: auto;
}

h3 {
  color: #8e44ad;
  margin-top: 0;
  margin-bottom: 10px;
}

.image-description {
  color: #333;
}

.image-description a {
  color: #8e44ad;
  text-decoration: none;
  transition: color 0.3s ease;
}

.image-description a:hover {
  color: #e84393;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    max-width: none;
  }

  .nav-button {
    font-size: 20px;
    padding: 8px 12px;
  }

  .modal-image-container {
    min-height: 200px;
  }

  .modal-image-container img {
    max-height: 40vh;
  }

  .modal-text-container {
    max-height: 40vh;
  }
}
